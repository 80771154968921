/* Track */
::-webkit-scrollbar {
    width: 10px; /* Set the width of the scrollbar */
    height: 0;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--p-color); /* Set the color of the scrollbar handle */
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Set the color of the scrollbar handle on hover */
  }

body,h1, h2,h3,h4,h5,h6,p{
    padding: 0;
    margin: 0;
}
button{
    border: none;
    background-color: transparent;
    padding: 0;
}
a{
  /* text-decoration: none; */
}
.main-body{
    /* display: flex; */
    /* flex-direction: row; */
    width: 100%;
    min-height: 100vh;
    background-color: var(--bgc);
    position: relative;
}
.body{
    padding: 1.2vw 3.3vw;
}

/* mobile responsive */
@media (max-width: 767px) {
    .body{
      padding: 2vw 3.3vw;
    }
}